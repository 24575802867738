// import { initFrontpageMediaSlider } from './components/frontpage/mediaSlider'
// import { initFrontpageTopSlider } from './components/frontpage/topSlider'
// import { initNewsletter } from './components/frontpage/newsletter'

// initFrontpageMediaSlider();
// initFrontpageTopSlider();
// initNewsletter();

// import Swiper JS
import Swiper from "swiper";
import { Pagination, Autoplay, Navigation, Breakpoints } from "swiper/modules";

const frontPageSlider = new Swiper("#slider-news", {
  grabCursor: true,
  modules: [Navigation],
  slidesPerView: "auto",
  spaceBetween: 20,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    576: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    991: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
});

const swiper7 = new Swiper("#slider-02", {
  grabCursor: true,
  autoHeight: true,
  modules: [Pagination, Autoplay],
  // autoplay: {
  //   delay: 2500,
  //   disableOnInteraction: false,
  // },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<button type="button" class="' +
        className +
        '">' +
        (index + 1) +
        "</button>"
      );
    },
  },
  on: {
    slideChange: function (object) {
      const $element = document.querySelector("[data-active-slide]");
      if ($element) {
        let value = parseInt(
          String($element.style.getPropertyValue("--el-rotate")).replace(
            "deg",
            ""
          ),
          10
        );
        let step = Math.abs(object.previousIndex - object.activeIndex);
        if (object.previousIndex < object.activeIndex) {
          value += 120 * step;
        } else {
          value -= 120 * step;
        }

        $element.style.setProperty("--el-rotate", String(value) + "deg");
        $element.setAttribute("data-active-slide", object.activeIndex);
      }
    },
  },
});
